import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

// type is an interface wihtout subscribed properties.
type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
};

// children is one of props. - children has the content of button, in this case, "entrar"
// ...rest has all the rest of props of button attributes
// React.FC already put childern.

const Button: React.FC<ButtonProps> = ({ children, loading, ...rest }) => (
  <Container type="button" {...rest}>
    {loading ? 'Carregando...' : children}
  </Container>
);

export default Button;
