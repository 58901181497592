import React, { useCallback, useRef } from 'react'; // useRef allow me to access the form properties
import { FiArrowLeft, FiMail, FiUser, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';

import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

import logoImg from '../../assets/logo.svg';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { Container, Content, AnimationContainer, Background } from './styles';

interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  let history = useHistory();

  // console.log(formRef);

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      // we should validate the data content
      // console.log(data);
      try {
        formRef.current?.setErrors({}); // cleaning errors

        const schema = Yup.object().shape({
          name: Yup.string().required('name is mandatory'),
          email: Yup.string()
            .required('email is madatory')
            .email('type a valid email'),
          password: Yup.string()
            // .required('password is mandatory')
            .min(6, 'must have at least 6 characters'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/users', data);

        history.push('/');

        addToast({
          type: 'success',
          title: 'User Signed up!',
          description: 'you are allowed to sign in into GoBarber.',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'success',
          title: 'authentication error',
          description: 'Sign up error. Check the credentials out',
        });
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      <Background />
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="GoBarber" />
          {
            // setting default value for field name with 'Marcos'
            // <Form initialData={{ name: 'Marcos' }} onSubmit={handleSubmit}>
          }
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Faca seu cadastro</h1>
            <Input icon={FiUser} name="name" placeholder="Name" />
            <Input icon={FiMail} name="email" placeholder="E-mail" />
            <Input
              icon={FiLock}
              name="password"
              type="password"
              placeholder="Senha"
            />
            <Button type="submit">Cadastrar</Button>
          </Form>
          <Link to="/">
            <FiArrowLeft />
            Voltar para logon
          </Link>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignUp;
